import { computePosition, flip, offset } from "@floating-ui/dom";
import gsap from "gsap";

export default class LowerSubmenuItem {
    private itemsWithChildren: HTMLElement[];

    private arrow: HTMLButtonElement | null = null;
    private ref: HTMLElement | null = null;
    private floating: HTMLElement | null = null;

    constructor(private readonly root: HTMLElement) {
        this.itemsWithChildren = [...this.root.querySelectorAll<HTMLElement>(".has-children[js-drop-down-button]")];
    }

    public init = async () => {
        this.listeners();
    };

    public destroy = async () => {
        this.removeListeners();
    };

    private show = ({ currentTarget }: MouseEvent) => {
        this.getSubmenuElements(currentTarget);

        gsap.to(this.floating, {
            autoAlpha: 1,
            pointerEvents: "auto",
            duration: 0.3,
            left: "100%",
            zIndex: "-1",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
            onStart: () => {
                this.update();
            },
        });

        gsap.to(this.arrow, { rotate: "-90deg", duration: 0.1 });
    };

    private hide = () => {
        if (!this.floating) return;
        gsap.to(this.floating, { autoAlpha: 0, pointerEvents: "none", duration: 0.3, left: "0", boxShadow: "rgba(0, 0, 0, 0) 0px 1px 3px 0px, rgba(0, 0, 0, 0) 0px 1px 2px 0px" });

        gsap.to(this.arrow, { rotate: "0", duration: 0.1 });
    };

    private update = () => {
        computePosition(this.root, this.floating as HTMLElement, {
            placement: "right-start",
            middleware: [
                flip(),
                offset({
                    crossAxis: 8,
                }),
            ],
        }).then(({ x, y }) => {
            Object.assign(this.floating!.style, {
                left: `${x}px`,
            });
        });
    };

    private getSubmenuElements = (item) => {
        this.ref = item as HTMLElement;
        this.floating = this.ref.querySelector("[js-submenu]") as HTMLUListElement;
        this.arrow = this.ref.querySelector(".submenu__more");
    };

    private removeListeners = () => {
        this.itemsWithChildren.forEach((item) => {
            item.removeEventListener("mouseenter", this.show);
            item.removeEventListener("mouseleave", this.hide);
        });
    };

    private listeners = () => {
        this.itemsWithChildren.forEach((item) => {
            item.addEventListener("mouseenter", this.show);
            item.addEventListener("mouseleave", this.hide);
        });
    };
}
